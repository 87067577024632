export const themeModule = {
    namespaced: true,
    state: {
        navbar: {
            logo: ''
        },
        sidebar: {
            minimized: false,
            navigations: [
                {
                    header: 'Apps & Pages',
                },
                /* {
                     icon: 'TagIcon',
                     title: 'Pricing',
                     route: '/packages',
                     active: false,
                 },*/
                {
                    icon: 'HomeIcon',
                    title: 'Dashboard',
                    children: [
                        {
                            icon: 'PieChartIcon',
                            title: 'Stats',
                            route: '/dashboard/contract-rent',
                            active: false,
                        },
                        {
                            icon: 'BarChart2Icon',
                            title: 'Monthly Stats',
                            route: '/dashboard/contract-rent/monthly',
                            active: false,
                        },
                    ]
                },
               /* {
                    icon: 'CalendarIcon',
                    title: 'Calendars',
                    children: [
                        {
                            icon: 'CalendarIcon',
                            route: '/treasureRent',
                            title: 'Simple Calendar',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-timeline',
                            route: '/calendar/time-line/rents',
                            title: 'Multi Calendar',
                            active: false,
                        },
                    ]
                },
*/
                {
                    icon_type: "fontawesome",
                    icon: 'fas fa-tasks',
                    title: 'Kanban',
                    route: '/kanban',
                    active: false,
                },
                /*{
                    icon_type: "fontawesome",
                    icon: 'far fa-arrow-alt-circle-up',
                    title: 'Export Real estates',
                    route: '/exports',
                    active: false,
                },*/

                /* {
                     icon_type: "fontawesome",
                     icon: 'far fa-building',
                     title: 'Ksv',
                     route: '/ksvs',
                     active: false,
                 }, */
                {
                    icon_type: "fontawesome",
                    icon: 'fa fa-file-alt',
                    title: 'Contracts',
                    children: [
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-file-alt',
                            title: 'Rent contracts',
                            route: '/real_estate/contracts/rent',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-file-alt',
                            title: 'Rent contracts 30+',
                            route: '/real_estate/contracts/rent30',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'fa fa-question-circle',
                            title: 'Inquiries',
                            route: '/real_estate/contracts/inquiry',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-file-alt',
                            title: 'Contract Templates',
                            route: '/real_estate/contracts/templates',
                            active: false,
                        },
                    ]


                },
                {
                    icon_type: "fontawesome",
                    icon: 'fa-solid fa-bell-concierge',
                    route: '/real_estate/contracts/features/guest',
                    title: 'Guest features',
                    active: false,
                },

                {
                    icon: 'SendIcon',
                    title: 'Messages',
                    children: [

                        {
                            icon: 'SendIcon',
                            title: 'Messages',
                            route: '/messages',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-file',
                            title: 'Messages Template',
                            route: '/messageTemplate',
                            active: false,
                        },
                        {
                            icon: 'MessageSquareIcon',
                            route: '/clients/sms',
                            title: 'Client Sms log',
                            active: false,
                        }
                    ]


                },
                {
                    icon_type: "fontawesome",
                    icon: 'fa fa-lock',
                    title: 'Electronic Locks',
                    children: [
                        {
                            icon_type: "fontawesome",
                            icon: 'fa fa-lock',
                            title: 'Nuki devices',
                            route: '/real_estate/nuki',
                            active: false,
                        }],
                },

                /*  {
                      icon: 'HomeIcon',
                      title: 'Store Insights',
                      route: '/dashboard',
                      active: false,
                  },*/
                /*   {
                       icon: 'HomeIcon',
                       title: 'Property Insights',
                       route: '/dashboardProperties',
                       active: false,
                   },*/
                /*   {
                       icon: 'TagIcon',
                       title: 'Pricing',
                       route: '/packages',
                       active: false,
                   },*/
                /* {
                     icon: 'MonitorIcon',
                     title: 'Treasure Live  🔴',
                     route: '/treasureLive',
                     active: false,
                 },*/

                /* {
                     icon: 'CalendarIcon',
                     route: '/calendar',
                     title: 'Calendar',
                     active: false,
                 },*/
                /*     {
                         icon: 'RadioIcon',
                         title: 'Ads',
                         active: false,
                         children: [
                             {
                                 icon: 'RadioIcon',
                                 route: '/ads',
                                 title: 'Ads',
                                 active: false,
                             },
                             {
                                 icon: 'CastIcon',
                                 route: '/ads/campaigns',
                                 title: 'Campaigns',
                                 active: false,
                             },
                             {
                                 icon: 'CastIcon',
                                 route: '/ads/strategies',
                                 title: 'Strategies',
                                 active: false,
                             },
                         ]
                     },
                     {
                         icon: 'SmartphoneIcon',
                         route: '/devices',
                         title: 'Devices',
                         active: false,
                     },*/
                {
                    icon: 'UsersIcon',
                    title: 'Clients',
                    active: false,
                    children: [
                        {
                            icon: 'UsersIcon',
                            title: 'All Clients',
                            route: '/clients',
                            active: false,
                        },
                        {
                            icon: 'UsersIcon',
                            title: 'Renter',
                            route: '/renters',
                            active: false,
                        },
                        {
                            icon: 'UsersIcon',
                            title: 'Owner',
                            route: '/owners',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-building',
                            title: 'Companies',
                            route: '/banks',
                            active: false,
                        },
                        {
                            icon: 'UsersIcon',
                            title: 'Priority Clients',
                            route: '/priorityClients',
                            active: false,
                        },
                        /* {
                             icon: 'UserXIcon',
                             title: 'Deleted Clients',
                             route: '/clients/deleted',
                             active: false,
                         },*/
                        /*    {
                                icon: 'FileTextIcon',
                                title: 'Notes',
                                route: '/clients/notes',
                                active: false,
                            },*/
                        /* {
                             icon: 'SettingsIcon',
                             route: '/clients/settings',
                             title: 'Settings',
                             active: false,
                         },
                        ,*/
                    ]
                },

                {
                    title: 'Monetary',
                    icon: 'CreditCardIcon',
                    route: '/report/monetary',
                    active: false,
                    children: [
                        /*{
                            icon: 'CreditCardIcon',
                            route: '/report/monetary',
                            title: 'Payment reports',
                            active: false,
                        },*/
                        {
                            icon_type: "fontawesome",
                            icon: 'fas fa-file-invoice-dollar',
                            route: '/report/monetary/invoices',
                            title: 'Invoices',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'fas fa-file-invoice-dollar',
                            route: '/report/monetary/cashbook',
                            title: 'Cashbook',
                            active: false,
                        },
                        {
                            icon: 'CreditCardIcon',
                            title: 'Deposits',
                            route: '/real_estate/deposits',
                            active: false,
                        },
                    ]

                },
                {
                    title: 'Orders',
                    icon: 'ShoppingCartIcon',
                    route: '/report/monetary',
                    active: false,
                    children: [
                        {
                            icon: 'ShoppingCartIcon',
                            route: '/report/orders',
                            title: 'All Orders',
                            active: false,
                        }
                    ]

                },
                /*     {
                         title: 'Products',
                         icon: 'ShoppingCartIcon',
                         active: false,
                         children: [
                             {
                                 icon: 'ShoppingCartIcon',
                                 title: 'All Products',
                                 route: '/product',
                                 active: false,
                             }, {
                                 icon: 'ShoppingCartIcon',
                                 title: 'All deleted Products',
                                 route: '/deletedProduct',
                                 active: false,
                             },
                             {
                                 icon: 'ShoppingCartIcon',
                                 title: 'All Vouchers',
                                 route: '/coupon',
                                 active: false,
                             },
                             {
                                 icon: 'ShoppingCartIcon',
                                 title: 'Categories',
                                 route: '/category',
                                 active: false,
                             }
                         ]

                     },*/

                {
                    title: 'RealEstates',
                    icon_type: "fontawesome",
                    icon: 'far fa-building',
                    active: false,
                    children: [


                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-building',
                            title: 'Buildings',
                            route: '/buildings',
                            active: false,
                        },


                        /* {
                             icon: 'CreditCardIcon',
                             title: 'RefinancePlace',
                             route: '/refinances',
                             active: false,
                         },*/
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-building',
                            title: 'Active Real estates',
                            route: '/real_estate',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'far fa-building',
                            title: 'Inactive Real estates',
                            route: '/deleted_real_estate',
                            active: false,
                        },
                        {
                             icon_type: "fontawesome",
                             icon: 'fa-solid fa-house-chimney-crack',
                             title: 'Damages',
                             route: '/real_estate/damages',
                             active: false,
                         },


                    ]
                },   {
                    title: 'Services',
                    icon_type: "fontawesome",
                    icon: 'fa fa-wrench',
                    active: false,
                    children: [


                        {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-wifi',
                            title: 'Routers',
                            route: '/services/routers',
                            active: false,
                        },  {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-bolt',
                            title: 'Electricity provider',
                            route: '/services/electros',
                            active: false,
                        },  {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-fire',
                            title: 'Gas provider',
                            route: '/services/gas',
                            active: false,
                        },

                    ]
                },

                {
                    icon_type: "fontawesome",
                    icon: 'fa-solid fa-broom',
                    title: 'Cleaning',
                    children: [
                        {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-broom',
                            title: 'Cleaning Tasks',
                            route: '/cleaning/tasks',
                            active: false,
                        },
                        {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-broom',
                            title: 'Cleaning History',
                            route: '/cleaning/history',
                            active: false,
                        }
                    ],
                },
              /*  {
                    title: 'Prices',
                    icon_type: "fontawesome",
                    icon: 'far fa-credit-card',
                    active: false,
                    children: [


                        {
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-chart-line',
                            title: 'Dynamic prices',
                            route: '/real_estate/price-labs',
                            active: false,
                        },/!*{
                            icon_type: "fontawesome",
                            icon: 'fa-solid fa-chart-line',
                            title: 'Static prices',
                            route: '/real_estate/static-prices',
                            active: false,
                        }*!/
                    ]

                },*/ {
                    title: 'Support',
                    icon_type: "fontawesome",
                    icon: 'far fa-life-ring',
                    active: false,
                    children: [


                        {

                            icon: 'TagIcon',
                            title: 'Support tickets',
                            route: '/real_estate/ticket',
                            active: false,
                        },
                    ]

                },
                {

                    title: 'Pricelist',
                    route: '/packages',
                    icon: 'TagIcon',
                    active: true,
                },

                /*{
                    title: 'Coupon Campaigns',
                    icon: 'CastIcon',
                    active: false,
                    route: '/ccampaign',
                },

                {

                    title: 'Databrain',
                    route: '/databrain',
                    active: false,
                },*/
                {

                    title: 'Chat',
                    route: '/chat',
                    icon: 'MessageSquareIcon',
                    active: false,
                },
                {
                    header: 'Administrative',
                },
                {
                    title: 'Organization',
                    icon_type: "fontawesome",
                    icon: 'far fa-building',
                    children: [
                        {
                            route: '/organization',
                            title: 'Organization',
                            active: false,
                        },
                        {
                            icon: 'TagIcon',
                            title: 'Onboarding Properties',
                            route: '/vips',
                            active: false,
                        },
                        {
                            route: '/whitelabel',
                            title: 'Whitelabel',
                            active: false,
                        },
                        {
                            route: '/organization/branches',
                            title: 'Branches',
                            active: false,
                        },

                        {
                            route: '/organization/affiliate',
                            title: 'Affiliate',
                            active: false,
                        },
                        /*  {
                              route: '/organization/mailevents',
                              title: 'Email',
                              active: false,
                          },*/


                    ]
                },
                {
                    title: 'Users',
                    icon: 'far fa-user',
                    icon_type: 'fontawesome',
                    active: false,
                    children: [
                        {
                            icon: 'far fa-user',
                            icon_type: 'fontawesome',
                            route: '/organization/users',
                            title: 'User list',
                            active: false,
                        },
                        {
                            icon: 'CheckIcon',
                            route: '/organization/users/permissions',
                            title: 'Permissions',
                            active: false,
                        },
                    ],
                },
                /*{
                    title: 'Calls',
                    icon: 'PhoneCallIcon',
                    route: '/report/calls',
                    active: false,
                },*/
                {
                    title: 'Statistics',
                    icon: 'PieChartIcon',
                    children: [
                        {
                            route: '/statistics/affiliate',
                            title: 'Affiliate',
                            active: false,
                        },
                        {
                            route: '/statistics/client',
                            title: 'Clients',
                            active: false,
                        },
                        {
                            route: '/statistics/user',
                            title: 'Users',
                            active: false,
                        },
                        {
                            route: '/statistics/advertising',
                            title: 'Advertising',
                            active: false,
                        }

                    ]
                },

                /* {
                     title: 'System',
                     icon: 'HardDriveIcon',
                     children: [
                         {
                             route: '/system/tracker',
                             title: 'Tracker',
                             active: false,
                         },
                         {
                             route: '/system/server',
                             title: 'Server report',
                             active: false,
                         }
                     ]
                 }*/
            ]
        }
    },
    mutations: {
        sidebarToggle(state) {
            state.minimized = !state.minimized
        },
        setLogo(state, logo) {
            state.navbar.logo = logo;
        },
        /***
         *
         * @param state
         * @param route{string}
         * @param index {number}
         * @param childIndex {number}
         */
        activeroute(state, {route, navIndex, childIndex}) {
            if (state == null)
                return;

            if (navIndex != null && state.sidebar.navigations[navIndex] != null) {
                state.sidebar.navigations[navIndex].active = true;
                if (childIndex != null) {
                    if (state.sidebar.navigations[navIndex].children[childIndex] != null)
                        state.sidebar.navigations[navIndex].children[childIndex].active = true;
                }
                return;
            }

            if (route == null || typeof route != "string")
                return;
            let firstParentroute = route.split("/")[1];
            let index = 0;
            switch (firstParentroute) {
                case "organization":
                    index = 1;
                    break;
                case "report":
                    index = 2;
                    break;
                case "clients":
                    index = 3;
                    break;
                case "options":
                    index = 4;
                    break;
                default:
                    return;

            }
            let nav = state.sidebar.navigations[index];
            if (nav.children == null) return;
            nav.children.forEach(n => {
                if (n.route !== route)
                    return;
                n.active = true;
                //break the loop
                return false;
            })
        },
        desactiveroute(state, route) {
            if (state == null)
                return;

            let firstParentroute = route.split("/");
            let index = 0;
            switch (firstParentroute) {
                case "organization":
                    index = 1;
                    break;
                case "report":
                    index = 2;
                    break;
                case "clients":
                    index = 3;
                    break;
                case "options":
                    index = 4;
                    break;
                default:
                    return;

            }
            let nav = state.navigations[index];
            if (nav.children == null) return;
            nav.children.forEach(n => {
                if (n.route !== route)
                    return;
                n.active = false;
                //break the loop
                return false;
            })
        }
    },
    getters: {
        navbar(state) {
            if (state.navbar == null)
                return {};
            return state.navbar;
        },
        navigation(state) {
            return state.sidebar.navigations.reduce((arr, nav) => {
                if (nav.active === false)
                    return arr;
                let children = nav["children"];
                if (children != null) {
                    children = nav["children"].filter(c => c.active === true);
                    if (children.length <= 0)
                        return arr;
                }
                arr.push({...nav, 'children': children});
                return arr;
            }, []);
        },
        sidebar(state) {
            if (state.sidebar == null)
                return {};
            return state.sidebar;
        },
        logo(state) {
            return state.navbar.logo;
        },
    },
    actions: {
        generateTheme({state, commit, rootGetters}, {logo}) {

            commit("setLogo", logo);
            //Check each nav if access to url its allowed
            state.sidebar.navigations.forEach((n, i) => {
                //if parent has route and its not active we check if access its allowed
                if (n.route != null && n.active != null && n.active !== true) {
                    let canAcess = rootGetters['user/canAccessToPage'](n.route);
                    if (canAcess === true)
                        commit('activeroute', {navIndex: i});
                }

                // In case that nav doent contains children, continue
                if (n.children == null)
                    return;
                //Check the same to the children
                n.children.forEach((ch, j) => {
                    //Check if children has route and is not active
                    if (ch.route != null && ch.active != null && ch.active !== true) {
                        if (rootGetters["user/canAccessToPage"](ch.route) === true)
                            commit('activeroute', {navIndex: i, childIndex: j})
                    }
                })
            });
        }
    }
};
