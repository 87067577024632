const Permissions = {
    ADS: {
        name: "ads",
        description: "Manage advertises",
        active: 1,
        module: "advertise"
    },
    AFFILIATE: {
        name: "affiliate",
        description: "Affiliate management",
        active: 1,
        module: "affiliate"
    },
    ALL_BUSINESSUNIT: {
        name: "all_businessunit",
        description: "Authorization to access all businessunits",
        active: 1,
        module: "system"
    },
    ALL_CLIENTS: {
        name: "all_clients",
        description: "Access to all clients",
        active: 1,
        module: "clients"
    },
    ALL_EVENTS: {
        name: "all_events",
        description: "Calendar access if affiliate to client",
        active: 1,
        module: "calendar"
    },
    ALL_MONETARY_TRANSACTION: {
        name: "all_monetary_transaction",
        description: "Access to general transactions",
        active: 1,
        module: "monetary"
    },
    ALLCALENDAR: {
        name: "allcalendar",
        description: "Access all calendar in business unit",
        active: 1,
        module: "calendar"
    },
    BANK: {
        name: "bank",
        description: "Bank Module",
        active: 1,
        module: "real_estate"
    },
    BANKERREFINANCEEXLUSIVE: {
        name: "bankerRefinanceExlusive",
        description: "Show less data in Refinance for Banker",
        active: 1,
        module: "real_estate"
    },
    BUILDING: {
        name: "building",
        description: "Building module",
        active: 1,
        module: "real_estate"
    },
    BUILDING_ALL_BU: {
        name: "building_all_bu",
        description: "All buildings branches",
        active: 1,
        module: "real_estate"
    },
    BUILDING_ALL_USER: {
        name: "building_all_user",
        description: "All Buildings user",
        active: 1,
        module: "real_estate"
    },
    BUILDING_EXPOSE: {
        name: "building_expose",
        description: "Upload expose",
        active: 1,
        module: "real_estate"
    },
    BUSINESSUNIT: {
        name: "businessunit",
        description: "Businessunit management",
        active: 1,
        module: "system"
    },
    CALENDAR: {
        name: "calendar",
        description: "Calendar Space",
        active: 1,
        module: "calendar"
    },
    CAMPAIGN: {
        name: "campaign",
        description: "Manage campaigns",
        active: 1,
        module: "advertise"
    },
    CLEANING: {
        name: "cleaning",
        description: "Cleaning management",
        active: 1,
        module: "cleaning"
    },
    CLIENT: {
        name: "client",
        description: "Client Space",
        active: 1,
        module: "clients"
    },
    CLIENT_DOCUMENTS: {
        name: "client_documents",
        description: "Upload and download client document",
        active: 1,
        module: "clients_document"
    },
    CLIENT_EMAIL: {
        name: "client_email",
        description: "Access client email",
        active: 1,
        module: "clients"
    },
    CLIENT_NAME: {
        name: "client_name",
        description: "Access client name",
        active: 1,
        module: "clients"
    },
    CLIENT_PASSWORD: {
        name: "client_password",
        description: "Access client password",
        active: 1,
        module: "clients"
    },
    CLIENT_PERSONAL: {
        name: "client_personal",
        description: "Authorization to access personal client",
        active: 1,
        module: "clients"
    },
    CLIENT_PHONE: {
        name: "client_phone",
        description: "Access client phone",
        active: 1,
        module: "clients"
    },
    CLIENT_REP: {
        name: "client_rep",
        description: "Client representative management",
        active: 1,
        module: "clients"
    },
    CLIENT_SMS: {
        name: "client_sms",
        description: "Send sms to client",
        active: 1,
        module: "clients"
    },
    CLIENT_STATUS: {
        name: "client_status",
        description: "Client status management",
        active: 1,
        module: "clients"
    },
    CLIENT_USERNAME: {
        name: "client_username",
        description: "Access client username",
        active: 1,
        module: "clients"
    },
    COUPON_CAMPAIGN: {
        name: "coupon_campaign",
        description: "Coupon Camapigns",
        active: 1,
        module: "product"
    },
    DASHBOARD: {
        name: "dashboard",
        description: "Dashboard Space",
        active: 1,
        module: "dashboard"
    },
    DATABRAIN: {
        name: "databrain",
        description: "Databrain home",
        active: 1,
        module: "databrain"
    },
    DELETEDCLIENTS: {
        name: "deletedClients",
        description: "Access to deleted CLients",
        active: 1,
        module: "clients"
    },
    DEVICE: {
        name: "device",
        description: "Manage devices",
        active: 1,
        module: "device"
    },
    EMAIL: {
        name: "email",
        description: "Email Space",
        active: 1,
        module: "mail"
    },
    EXTERNAL_API: {
        name: "external_api",
        description: "Access to external api credentials",
        active: 1,
        module: "external_api"
    },
    FACE_RECOGNITION: {
        name: "face_recognition",
        description: "Access face recognition data",
        active: 1,
        module: "dashboard"
    },
    FIXLEAD: {
        name: "fixlead",
        description: "Access to fixlead",
        active: 1,
        module: "clients"
    },
    FTP: {
        name: "ftp",
        description: "Access to ftp client data",
        active: 1,
        module: "admin"
    },
    INVOICE: {
        name: "invoice",
        description: "Invoice management",
        active: 1,
        module: "invoice"
    },
    JUSTIMMOBUTTON: {
        name: "justimmoButton",
        description: "JustImmo button for update",
        active: 1,
        module: "real_estate"
    },
    KANBAN: {
        name: "kanban",
        description: "Kanban",
        active: 1,
        module: "real_estate"
    },
    KSV: {
        name: "ksv",
        description: "Ksv details",
        active: 1,
        module: "real_estate"
    },
    MAILEVENT: {
        name: "mailevent",
        description: "Email event management",
        active: 1,
        module: "mail"
    },
    MESSAGES: {
        name: "messages",
        description: "Messages system",
        active: 1,
        module: "real_estate"
    },
    MONETARY: {
        name: "monetary",
        description: "Monetary Space",
        active: 1,
        module: "monetary"
    },
    MONETARY_TRANSACTION_PERSONAL: {
        name: "monetary_transaction_personal",
        description: "Access to personal transactions",
        active: 1,
        module: "monetary"
    },
    NOTE: {
        name: "note",
        description: "Access to notes",
        active: 1,
        module: "note"
    },
    NUKI: {
        name: "nuki",
        description: "Nuki smart devices management",
        active: 1,
        module: "real_estate"
    },
    ORDER: {
        name: "order",
        description: "Order management",
        active: 1,
        module: "order"
    },
    ORGANIZATION: {
        name: "organization",
        description: "Organization management",
        active: 1,
        module: "admin"
    },
    PCALENDAR: {
        name: "pcalendar",
        description: "Access to personal calendar",
        active: 1,
        module: "calendar"
    },
    PERMISSION: {
        name: "permission",
        description: "Authorization role management",
        active: 1,
        module: "system"
    },
    PHONE: {
        name: "phone",
        description: "Call Space",
        active: 1,
        module: "phone"
    },
    PRICING: {
        name: "pricing",
        description: "Pricing management",
        active: 1,
        module: "pricing"
    },
    PRODUCT: {
        name: "product",
        description: "Product management",
        active: 1,
        module: "product"
    },
    REAL_ESTATE: {
        name: "real_estate",
        description: "Real estate management",
        active: 1,
        module: "real_estate"
    },
    REAL_ESTATE_ACTIVE: {
        name: "real_estate_active",
        description: "Inserat module",
        active: 1,
        module: "real_estate"
    },
    SERVICES: {
        name: "services",
        description: "services module",
        active: 1,
        module: "services"
    },
    REAL_ESTATE_ALL: {
        name: "real_estate_all",
        description: "All real estates",
        active: 1,
        module: "real_estate"
    },
    REAL_ESTATE_BOOKING: {
        name: "real_estate_booking",
        description: "RealEstate appointment module",
        active: 1,
        module: "real_estate"
    },
    REAL_ESTATE_CONTRACT: {
        name: "real_estate_contract",
        description: "real estate contract",
        active: 1,
        module: "real_estate"
    },
    REALESTATE_DASHBOARD: {
        name: "realestate_dashboard",
        description: "Real Estate Dashboard",
        active: 1,
        module: "real_estate"
    },
    REFINANCE: {
        name: "refinance",
        description: "Refinance Module",
        active: 1,
        module: "real_estate"
    },
    REPORT: {
        name: "report",
        description: "NULL",
        active: 1,
        module: "system"
    },
    ROLE: {
        name: "role",
        description: "Creation role management",
        active: 1,
        module: "system"
    },
    SENDMAIL: {
        name: "sendmail",
        description: "Access to custom mail",
        active: 1,
        module: "mail"
    },
    SETTINGS: {
        name: "settings",
        description: "Access to settings",
        active: 1,
        module: "system"
    },
    SMTP: {
        name: "smtp",
        description: "Email settings",
        active: 1,
        module: "mail"
    },
    STATISTICS: {
        name: "statistics",
        description: "NULL",
        active: 1,
        module: "statistics"
    },
    STRATEGY: {
        name: "strategy",
        description: "Strategy management for advertises",
        active: 1,
        module: "advertise"
    },
    SUPPORT_TICKET: {
        name: "support_ticket",
        description: "Support ticket module",
        active: 1,
        module: "real_estate"
    },
    SYSTEM: {
        name: "system",
        description: "NULL",
        active: 1,
        module: "system"
    },
    TREASURE_LIVE: {
        name: "treasure_live",
        description: "See all treausure live data",
        active: 1,
        module: "dashboard"
    },
    TREASURE_RENT: {
        name: "treasureRent",
        description: "Treasure rent calendar",
        active: 1,
        module: "real_estate"
    },
    USER: {
        name: "user",
        description: "User management",
        active: 1,
        module: "system"
    },
    USER_ALLBU: {
        name: "user_allbu",
        description: "User businnesunit management",
        active: 1,
        module: "system"
    },
    USER_HOURS: {
        name: "user_hours",
        description: "NULL",
        active: 1,
        module: "system"
    },
    VIP_CLUB: {
        name: "vipclub",
        description: "Wohnclub Module",
        active: 1,
        module: "real_estate"
    },
    VIP_CLUB_ADMIN: {
        name: "vipclubAdmin",
        description: "Admin Wohnclub permission",
        active: 1,
        module: "real_estate"
    },
    WHITELABEL: {
        name: "whitelabel",
        description: "Whitelabel management",
        active: 1,
        module: "admin"
    },
    NOT_ACTIVE: {
        name: "notaactive",
        description: "System not active",
        active: 1,
        module: "admin"
    }
}

export default Permissions;
