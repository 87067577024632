import api from "@/services/api.service";

export const realEstatesContractsModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        fileTags(state, getters, rootState, rootGetters) {
            return [
                {value: "unknown", title: "unknown", icon: "fas fa-question"},
                {value: "contract", title: "contract", icon: "fas fa-file-contract"},
                {value: "signedContract", title: "signed contract", icon: "fas fa-file-signature"},
                {value: "clientDocument", title: "client document Id", icon: "fas fa-passport"},
                {value: "clientIncomes", title: "client incomes", icon: "fas fa-university"},
                {value: "clientDocument", title: "client document", icon: "fas fa-id-card"},
                {value: "document", title: "document", icon: "fas fa-file-alt"}
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: "INQUIRY", text: 'INQUIRY'},
                {value: "ACTIVE", text: 'ACTIVE'},
                {value: 'REVIEWING', text: 'REVIEWING'},
                {value: 'WAITING_FOR_DATA', text: 'WAITING FOR DATA'},
                {value: 'OWNER_APPROVED', text: 'OWNER APPROVED'},
                {value: 'COMPLETED', text: 'COMPLETED'},
                {value: 'CANCELED', text: 'CANCELED'}
            ]
        },
    },
    mutations: {},
    actions: {
        getAllContracts(actions, {page, amount, filter, sortcolumn, sortby}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    page, amount, sortcolumn, sortby
                }, filter).then(
                    (ans) => {
                        if (ans["contracts"] == null)
                            return reject('Could not get contracts ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        getAllContractsCustom(actions, {page, amount, filter, sortcolumn, sortby}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    page, amount, sortcolumn, sortby
                }, filter).then(
                    (ans) => {
                        if (ans["contracts"] == null)
                            return reject('Could not get contracts ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        getById(actions, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/id/' + id).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not get contracts ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        getAllByClients(actions, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/client/' + id).then(
                    (ans) => {
                        if (ans["Contracts"] == null)
                            return reject('Could not get contracts ');

                        resolve(ans);
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },

        getContractParts(actions, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/get-parts/' + id).then(
                    (ans) => {
                        if (ans["parts"] == null)
                            return reject('Could not get contracts parts');
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        saveContractParts(actions, {id, textParts}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.baseApi().post('realEstate/contract/add-parts/' + id, textParts).then(
                    (ans) => {
                        let data = ans.data.objects;
                        if (data["parts"] == null)
                            return reject('Could not get save parts');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Contract parts'},
                                message: {text: '%s parts saved successfully', arguments: ['Contract']}
                            },
                            {root: true});
                        resolve(data);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        generateContractPdf(actions, contractId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/generateAndSave/' + contractId).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not generate and save contract');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Contract generator'},
                                message: {text: '%s generated and saved successfully', arguments: ['Contract']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Contract %s', arguments: ['couldnt been generated']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })

            })
        },
        signContract(actions, {contractId, signature}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.baseApi().post('realEstate/contract/sign/' + contractId, {signature})
                    .then((response) => {
                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['signed']},
                                message: {text: '%s signed successfully', arguments: ['Contract']}
                            },
                            {root: true});

                        resolve(response.data.objects);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Sign contract',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        generateAndSendLink(action, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/generateLink/' + id).then(
                    (ans) => {
                        if (ans["status"] == null)
                            return reject('Could not generate and send link ');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Link generator'},
                                message: {text: '%s generated and sent successfully', arguments: ['Link']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Link %s', arguments: ['send error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        sendCheckInDetails(action, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/checkInDetails/' + id).then(
                    (ans) => {
                        if (ans["status"] == null)
                            return reject('Could not  send details ');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'CheckIn details'},
                                message: {text: '%s sent successfully', arguments: ['Details']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Details %s', arguments: ['send error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        sendSMSCheckIn(action, id) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/checkInDetailsSms/' + id).then(
                    (ans) => {
                        if (ans["status"] == null)
                            return reject('Could not  send details ');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'CheckIn details'},
                                message: {text: '%s sent successfully', arguments: ['Details']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Details %s', arguments: ['send error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        cancelContract(action, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/cancel/' + id).then(
                    (ans) => {
                        if (ans["contractId"] == null)
                            return reject('Could not cancel contract');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Cancel contract'},
                                message: {text: '%s cancelled successfully', arguments: ['Contract']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Contract %s', arguments: ['cancellation error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        changeContract(action, {id, propertyId, hostawayApi}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/change/' + id + '/' + propertyId, {hostawayApi: hostawayApi}).then(
                    (ans) => {
                        if (ans["contractId"] == null)
                            return reject('Could not change contract');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Change contract'},
                                message: {text: '%s changed successfully', arguments: ['Contract']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Contract %s', arguments: ['cancellation error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        markPaymentAsPaid(action, {contractId, paymentId}) {
          return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/markPaymentAsPaid/' + contractId + '/' + paymentId).then(
                    (ans) => {
                        if (ans["contractId"] == null)
                            return reject('Could not change contract');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Change contract'},
                                message: {text: '%s changed successfully', arguments: ['Contract']}
                            },
                            {root: true});

                        resolve(ans);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Contract %s', arguments: ['update error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
          })

        },
        getMonetaryId(action, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/monetaryId/' + id).then(
                    (ans) => {
                        if (ans["monetaryId"] == null)
                            return reject('Could not change contract');
                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Invoice contract'},
                                message: {text: '%s  successfully', arguments: ['Fetched']}
                            },
                            {root: true});

                        resolve(ans["monetaryId"]);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Invoice %s', arguments: [' error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getCleaningId(action, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/cleaningId/' + id).then(
                    (ans) => {


                        resolve(ans["cleaningId"]);
                    }, (error) => {
                        action.dispatch('notifications/error',
                            {
                                title: {text: 'Invoice %s', arguments: [' error']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        createPdfInvoiceContract(actions, contractId) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/invoice/' + contractId, null, null)
                    .then(
                        (ans) => {
                            if (ans["id"] == null)
                                return reject('Could not generate invoice');

                            actions.dispatch('notifications/success',
                                {
                                    title: {text: 'Invoice %s', arguments: ['creation']},
                                    message: {text: '%s generated successfully', arguments: ['Invoice']}
                                },
                                {root: true});
                            resolve(ans["id"]);
                        }, (error) => {
                            actions.dispatch('notifications/error',
                                {
                                    title: {text: 'Invoice %s', arguments: ['Error']},
                                    message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                                },
                                {root: true});
                            reject(error.message)
                        })
            })
        },
        createContract(actions, contract) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/create/' + contract.propertyId, null, contract)
                    .then(
                        (ans) => {
                            if (ans["id"] == null)
                                return reject('Could not create contract');

                            actions.dispatch('notifications/success',
                                {
                                    title: {text: 'Contract %s', arguments: ['creation']},
                                    message: {text: '%s created successfully', arguments: ['Contract']}
                                },
                                {root: true});
                            resolve(ans["id"]);
                        }, (error) => {
                            console.log("error", error)
                            actions.dispatch('notifications/error',
                                {
                                    title: {text: 'Contract %s', arguments: ['Error']},
                                    message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                                },
                                {root: true});
                            reject(error.message)
                        })
            })
        },
        createContractMulti(actions, contract) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/createMulti', null, contract)
                    .then(
                        (ans) => {
                            if (ans["id"] == null)
                                return reject('Could not create contract');

                            actions.dispatch('notifications/success',
                                {
                                    title: {text: 'Contract %s', arguments: ['creation']},
                                    message: {text: '%s created successfully', arguments: ['Contract']}
                                },
                                {root: true});
                            resolve(ans["id"]);
                        }, (error) => {
                            actions.dispatch('notifications/error',
                                {
                                    title: {text: 'Contract %s', arguments: ['Error']},
                                    message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                                },
                                {root: true});
                            reject(error.message)
                        })
            })
        },
        checkCreateMulti(actions, contract) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/checkCreateMulti', null, contract)
                    .then(
                        (ans) => {

                            resolve(ans["Contracts"]);

                        }, (error) => {

                            reject(error.message)
                        })
            })
        },
        updateContract(action, {id, contract}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/contract/' + id, null, contract).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not update contract');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['details']},
                                message: {text: '%s updated successfully', arguments: ['Details']}
                            },
                            {root: true});
                        resolve(ans["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        depositWasPaid(action, {id, depositPaid}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/depositPaid/' + id, {depositPaid:depositPaid}, null).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not update contract');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['details']},
                                message: {text: '%s updated successfully', arguments: ['Details']}
                            },
                            {root: true});
                        resolve(ans["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }, depositWasRefunded(action, {id, depositRefunded}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/depositRefunded/' + id, {depositRefunded:depositRefunded}, null).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not update contract');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['details']},
                                message: {text: '%s updated successfully', arguments: ['Details']}
                            },
                            {root: true});
                        resolve(ans["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        changeContractOwner(action, {id, ownerId}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/contract/' + id + '/' + ownerId, null, null).then(
                    (ans) => {
                        if (ans["contract"] == null)
                            return reject('Could not update contract');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['details']},
                                message: {text: '%s updated successfully', arguments: ['Details']}
                            },
                            {root: true});
                        resolve(ans["contract"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateFilesData(action, {id, contract}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/files/' + id, null, contract).then(
                    (ans) => {
                        if (ans["documents"] == null)
                            return reject('Could not update files');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['files']},
                                message: {text: '%s updated successfully', arguments: ['Files']}
                            },
                            {root: true});
                        resolve(ans["documents"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateStatus(action, {id, status}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/status/' + id, null, status).then(
                    (ans) => {
                        if (ans["history"] == null)
                            return reject('Could not update status');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Contract %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['Status']}
                            },
                            {root: true});
                        resolve(ans["history"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateApproveStatus(action, {id, approveStatus}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/approve/' + id, null, approveStatus).then(
                    (ans) => {
                        if (ans["approveStatus"] == null)
                            return reject('Could not status');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Approvement %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['Approvement']}
                            },
                            {root: true});
                        resolve(ans["approveStatus"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateEditableFields(action, {id, editableFields}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/editable-fields/' + id, null, editableFields).then(
                    (ans) => {
                        if (ans["editableFields"] == null)
                            return reject('Could not status');

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Editable %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['Editable']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sproofRequest(actions, {contractId}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('sproof/requestSignature/' + contractId, null, null).then(
                    (ans) => {

                        actions.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s request sent successfully', arguments: ['Sproof']}
                            },
                            {root: true});
                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        getOfferFile(actions, {contractId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.downloadApi().get('/realEstate/contract/get-offer/' + contractId)
                    .then(response => {
                        console.log("downloadFile", response)
                        let contentType = response.headers["content-type"];
                        let fileName = "file";
                        if (contentType.includes("filename="))
                            fileName = contentType.substr(contentType.toString().indexOf("filename=") + 9)
                        resolve({data: new Blob([response.data], {type: 'application/pdf'}), fileName, contentType});
                    }, error => {
                        reject(error);
                    });
            });
        },

        signOffer(action, {contractId, signature}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/contract/offer/sign/' + contractId, null, {signature}).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'Offer %s', arguments: ['status']},
                                message: {text: '%s signed successfully', arguments: ['Offer']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
    }
}
