import api from '../../services/api.service'
import { router } from '../../router'

export const userModule = {
  namespaced: true,
  state: {
    timer: null,
    status: {
      loggedIn: false,
      loginFailure: false
    },
    user: {},
    mail: {},
    routers: {},
  },
  mutations: {
    loginRequest(state, user) {
      state.status = {
        loggedIn: false,
        loginFailure: false
      }
      state.user = user
    },
    loginSuccess(state, user) {
      state.status = {
        loggedIn: true,
        loginFailure: false
      }
      if (user != null) {
        state.user = {}
        state.user.Bearer = user.Bearer
        state.user.profile = user.profile
        state.user.profile.username = user.username
        state.user.role = user.role
        state.mail = user.smtp
      }
    },
    setRouters(state, router) {
      state.routers = router
    },
    loginFailure(state) {
      state.status = {
        loggedIn: false,
        loginFailure: true
      }
      state.user = {}
    },
    disconnection(state) {
      state.status = {
        loggedIn: false,
        loginFailure: false
      }
    },
    onLogout(state) {
      state.status = {
        loggedIn: false,
        loginFailure: false
      }
      state.user = {}
    },
    setTimer(state, timer) {
      state.timer = timer
    },
    stopTimer(state) {
      clearInterval(state.timer)
      state.timer = null
    }
  },
  getters: {
    isloggedIn(state) {
      return state.status.loggedIn
    },
    myId(state) {
      if (state.user == null || state.user.profile == null || state.user.profile.id == null) {
        return 0
      }
      return state.user.profile.id
    },
    myUsername(state) {
      if (state.user == null || state.user.profile == null || state.user.profile.username == null) {
        return null;
      }
      return state.user.profile.username
    },
    myAdminStatus(state) {
      if (state.user == null || state.user.profile == null || state.user.profile.isAdmin == null) {
        return false
      }

      return state.user.profile.isAdmin
    },
    myProfile(state) {
      if (state.user == null || state.user.profile == null || state.user.profile.id == null) {
        return {}
      }

      return state.user.profile
    },
    myName(state) {
      if (state.user == null || state.user.profile == null || state.user.profile.id == null) {
        return ''
      }

      return state.user.profile.firstname + ' ' + state.user.profile.lastname
    },
    myRole(state) {
      if (state.user == null || state.user.role == null) {
        return {}
      }
      return state.user.role

    },
    myRoleId(state) {
      if (state.user == null || state.user.role == null) {
        return ''
      }
      return state.user.role.id

    },
    myRoleName(state) {
      if (state.user == null || state.user.role == null) {
        return ''
      }
      return state.user.role.name

    },
    myEmails(state) {
      if (state.mail == null) {
        return {}
      }

      return state.mail
    },
    canRead: (state) => (permissionName) => {
      if (state == null || permissionName == null || typeof permissionName !== 'string' || state.user.role == null || state.user.role.permissions == null) {
        return false
      }

      if (state.user.role.permissions[permissionName] == null) {
        return false
      }

      return state.user.role.permissions[permissionName].read
    },
    canCreate: (state) => (permissionName) => {
      if (state == null || permissionName == null || typeof permissionName !== 'string' || state.user.role == null || state.user.role.permissions == null) {
        return false
      }

      if (state.user.role.permissions[permissionName] == null) {
        return false
      }

      return state.user.role.permissions[permissionName].create
    },
    canEdit: (state) => (permissionName) => {
      if (state == null || permissionName == null || typeof permissionName !== 'string' || state.user.role == null || state.user.role.permissions == null) {
        return false
      }

      if (state.user.role.permissions[permissionName] == null) {
        return false
      }

      return state.user.role.permissions[permissionName].edit
    },
    canOperate: (state) => (permissionName) => {
      if (state == null || permissionName == null || typeof permissionName !== 'string' || state.user.role == null || state.user.role.permissions == null) {
        return false
      }

      if (state.user.role.permissions[permissionName] == null) {
        return false
      }

      return state.user.role.permissions[permissionName].operation
    },
    /***
     *
     * @param state
     * @param getters
     * @param to{string}
     * @returns {function(string)}
     */
    canAccessToPage: (state, getters) => (to) => {
        // return true; //TODO: REMOVE THIS LINE
      if (state == null || to == null || typeof to != 'string') {
        return null
      }
      if (to.trim() === '/') {
        return true
      }
      if (to.slice(-1) === '/') {
        to = to.substring(0, to.length - 1)
      }
      if(to==='/error-404' || to==='/login' || to==='/not-authorized' || to==='/chat')
        return true;
      let routerPermissions = state.routers[to]
      if (routerPermissions == null) {
        routerPermissions = Object.values(state.routers)
          .find(routerPermission => routerPermission.find(router => router.pathType === 'start' && to.startsWith(router.path)) != null)
        if (routerPermissions == null) {
          return false
        }
      }


      for (let routerPermission of routerPermissions) {
        if(routerPermission.permissionLevel == null)
          continue;
        if (routerPermission.permissionLevel.includes('r') && !getters.canRead(routerPermission.permissionName)) {
          return false;
        }
        if (routerPermission.permissionLevel.includes('w') && !getters.canEdit(routerPermission.permissionName)) {
          return false;
        }
        if (routerPermission.permissionLevel.includes('o') && !getters.canOperate(routerPermission.permissionName)) {
          return false;
        }
      }

      return true;
    },
  },
  actions: {
    startPingSystem({ state, commit, dispatch }) {
      console.log("startPingSystem");
      if (state.timer != null) {
        return
      }

      if (window.config.ping == null || window.config.ping !== false) {
        commit('setTimer', setInterval(() => dispatch('ping'), (window.config.pingInterval || 10)*1000))
      }
    },
    login({ dispatch, commit }, { username, password }) {
      return new Promise(function (resolve, reject) {
        commit('loginRequest', { username })
        api.sendRequest('user/login', {
          username,
          password
        },)
          .then(
            user => {
              localStorage.setItem('Bearer', user.Bearer)
              //get full profile
              api.sendRequest('user/profile')
                .then((ans) => {
                  if (ans.profile != null) {
                    user.profile = ans.profile
                  }
                })
                .catch(function (error) {
                  console.log('login(): error ', error)
                })
                .finally(() => {
                  console.log('timeServer=' + user.serverTime + ', date.now=' + Date.now() + ', time diff=' + (Date.parse(user.serverTime) - Date.now()))
                  commit('loginSuccess', user)
                  commit('setRouters', user.routers)
                  commit('data/update',
                    {
                      affiliates: user.affiliates,
                      branches: user.branches,
                      languages: user.languages,
                      organization: user.organization,
                      serverTimeDiff: (Date.parse(user.serverTime) - Date.now()),
                      users: user.users,
                      userId: user.profile.id,
                      countries: user.countries,
                      roles: user.roles,
                      admin: user.admin,
                      BuGroups: user.BuGroups,
                    },
                    { root: true }
                  )
                  dispatch('calendar/getData', {}, { root: true })
                  dispatch('chat/connect', {} , { root: true })
                  dispatch('theme/generateTheme', { logo: user.organization['logoLink'], }, { root: true })
                  dispatch('startPingSystem')
                  resolve('/')

                })

            },
            error => {
              if (error.code === 401) {
                localStorage.removeItem('Bearer')
              }
              commit('loginFailure', error)
              reject(error.message)
            }
          )
      })

    },

    adminLogin(actions, orgId) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('user', {
          org: orgId,
          action: 'adminLogin'
        })
          .then(
            user => {
              localStorage.setItem('Bearer', user.Bearer)
              window.location.href = '/'

            },
            error => {
              actions.dispatch('notifications/error', {
                title: actions.rootGetters['translator/msg']('Connection'),
                message: actions.rootGetters['translator/msg'](error.message)
              }, { root: true })
              reject(error.message)
            }
          )
      })

    },
    returnAdminLogin(actions) {
      return new Promise(function (resolve, reject) {
        api.sendRequest('user', { action: 'returnAdminLogin' })
          .then(
            user => {
              localStorage.setItem('Bearer', user.Bearer)
              window.location.href = '/organization'

            },
            error => {
              actions.dispatch('notifications/error', {
                title: actions.rootGetters['translator/msg']('Connection'),
                message: actions.rootGetters['translator/msg'](error.message)
              }, { root: true })
              reject(error.message)
            }
          )
      })

    },

      ping({dispatch, commit, getters, rootGetters}) {
          let $this = this;
          this.$api.baseApi().get('user/ping',)
              .then(
                  ans => {
                      let headers = ans.headers;
                      let data = ans.data.objects;
                      // console.log("ping",ans)
                      if (data == null) {
                          return
                      }
                      if(headers['x-authorization'] != null){
                          localStorage.setItem('Bearer', headers['x-authorization'])
                      }

                      if (!getters.isloggedIn) {
                          dispatch('login', {})
                          return
                      }

                      dispatch('onNotification', data['notification'])
                  },
                  error => {
                      console.warn("ping failed", error.response)
                      console.log('ping() error: ', error.response)
                      if (error.response.status === 401 || error.response.status === 403) {

                          // commit("stopTimer");
                          localStorage.removeItem('Bearer')
                          //TODO: REDIRECT TO LOGIN
                          //router.push('/login?returnUrl=' + router.currentRoute);
                          //TODO: STICKY NOTIFICATION
                          if (getters.isloggedIn) {
                              commit('disconnection')
                              dispatch('notifications/error', {
                                  title: rootGetters['translator/msg']('Connection'),
                                  message: rootGetters['translator/msg']('You are disconnected changes will not be saved'),
                                  sticky: true
                              }, {root: true})
                          }
                      }
                      if (error.response.status == false ||  error.response.status === 0) {
                          dispatch('notifications/error', {
                              title: rootGetters['translator/msg']('Connection'),
                              message: rootGetters['translator/msg']('Connection to server failed')
                          }, {root: true})
                      }
                  }
              )

      },

    onNotification(actions, notifications) {
      if (notifications == null) {
        return
      }

      if (!Array.isArray(notifications)) {
        console.error('onNotification(): Notification is not an array!', notifications)
        return
      }

      if (notifications.length === 0) {
        return
      }

      console.log('onNotification() notifications', notifications)

      notifications.forEach((n) => {

        switch (n.event) {

          case 'LOGIN':
            actions.dispatch('notifications/success',
              {
                title: n.title,
                message: actions.rootGetters['translator/msg']('Has connected'),
                sticky: n.sticky,
              },
              { root: true })

            break

          case 'CALENDAR':
            if (n.notificationtype === 'SET') {

              actions.dispatch('notifications/success',
                {
                  title: n.title,
                  message: n.description,
                  sticky: n.sticky,
                },
                { root: true })
            }
            if (n.notificationtype === 'TRIGGER') {
              actions.dispatch('notifications/reminder',
                {
                  title: n.title,
                  message: n.description,
                  sticky: n.sticky,
                  audio: true,
                  audioType: 'long'
                },
                { root: true })

            }
            break

          case 'FORM':

            actions.dispatch('notifications/success',
              {
                title: n.title,
                message: n.description,
                sticky: n.sticky,
              },
              { root: true })

            break
        }

        /*  actions.dispatch('notifications/success',
              {title: n.title, message: n.description, sticky: n.sticky,},
              {root: true});*/
      })
    },
    logout({ commit }) {
      console.log('onLogout')
      return new Promise(function (resolve) {
        commit('stopTimer')
        commit('onLogout')
        api.sendRequest('user/logout')
          .finally((a) => {
            resolve(a)
            localStorage.Bearer = null
          })
      })
    },
    selectBuId({ commit }, buid) {
      return new Promise(function (resolve, reject) {
        console.log('user.module->selectBuId()', buid)
        let header = {}
        if (localStorage.Bearer != null) {
          header['Bearer'] = localStorage.Bearer
        }
        api.sendRequest('user', {
          buid: buid.toString(),
          action: 'selectedBranchId'
        }, header)
          .then(
            () => {
              commit('data/update', { selectedBranchId: buid }, { root: true })
            },
            error => {
              if (error.code === 401) {
                localStorage.removeItem('Bearer')
              }
              reject(error.message)
            }
          )
      })

    },
    checkLogin({ state, dispatch }) {
      return new Promise(function (resolve, reject) {
          if (state.status.loggedIn === true) {
            return resolve()
          }
          dispatch('login', {
            username: null,
            password: null
          })
            .then(resolve, reject)
        }
      )
    },
    canAccess({ state, dispatch, getters }, { to }) {
      return new Promise(function (resolve, reject) {
        const publicPages = ['/login', '/register']
        dispatch('checkLogin')
          .then(
            () => {
              if (publicPages.includes(to.path)) {
                return resolve(to.fullPath)
              }
              //check if page its login
              if (to.fullPath.trim()
                .startsWith("/login")) {
                return resolve('/');
              }
              //check if page its 404
              if (to.fullPath.trim() === "/404") {
                return resolve('/404');
              }
              //Check if user can access to this page
              if (getters['canAccessToPage'](to.path)) {
                resolve();
              } else {
                resolve('/not-authorized');
              } // In case that user cannot access redirect to 404 page
            })
          .catch(
            () => {
              if (publicPages.includes(to.path)) {
                return resolve(to.fullPath);
              }
              reject('/login')
            });
      })
    },

  }
};
