import {vue} from "../../../main";

export const guestFeatureModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchGuestFeaturesByBuid(actions, {buid}) {
            console.log("fetchGuestFeaturesByBuid", buid);
            let params = {buids: buid, page: 1, amount: 1000};
            return this.$api.baseApi().post('/features/guest/get', {}, {params})
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        fetchAllGuestFeature(actions, {page, buids, amount, filter, sortcolumn, sortby}) {
            console.log("fetchAllGuestFeature", {page, amount, filter, sortcolumn, sortby});
            let params = {
                buids: (buids ||  actions.rootGetters['data/getSelectedBranchesIds']).join(","),
                page,
                amount,
                sortcolumn,
                sortby
            };
            return this.$api.baseApi().post('/features/guest/get', filter, {params})
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        fetchGuestFeatureById(actions, id) {
            return this.$api.baseApi().get(`/features/guest/get/${id}`)
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['cleaning']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        insertGuestFeature(actions, request) {
            return this.$api.baseApi().post(`/features/guest/add`, request)
                .then(response => {
                    actions.dispatch('notifications/success',
                        {
                            // title: vue.$t('update_success'),
                            title: 'Inserted feature',
                            message: 'Task updated successfully',
                        },
                        {root: true});
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: 'Update feature',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        updateGuestFeature(actions, {id, request}) {
            return this.$api.baseApi().post(`/features/guest/update/${id}`, request)
                .then(response => {
                    actions.dispatch('notifications/success',
                        {
                            // title: vue.$t('update_success'),
                            title: 'Update feature',
                            message: 'Feature updated successfully',
                        },
                        {root: true});
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: 'Update feature',
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
    }
}
