import {vue} from "../../../main";

export const guestFeatureRequestModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchGuestFeaturesByContactId(actions, contractId) {
            return this.$api.baseApi().get('/realEstate/contract/feature/guest/get/requests/' + contractId)
                .then(response => {
                    return response.data?.objects.features;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        fetchAllGuestFeatureByContactId(actions, contractId) {
            return this.$api.baseApi().get('/realEstate/contract/feature/guest/get/allowed/' + contractId)
                .then(response => {
                    return response.data?.objects.features;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        getGuestFeaturesByContactId(actions, requestsId) {
            return this.$api.baseApi().get('/realEstate/contract/feature/guest/get/request/' + requestsId)
                .then(response => {
                    return response.data?.objects.feature;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        updateGuestFeaturesToContactId(actions, {featureId, request}) {
            return this.$api.baseApi().post('/realEstate/contract/feature/guest/update/' + featureId, request)
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        addGuestFeaturesToContactId(actions, {contractId, request}) {
            return this.$api.baseApi().post('/realEstate/contract/feature/guest/add/' + contractId, request)
                .then(response => {
                    return response.data?.objects;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        cancelGuestFeaturesByContactId(actions, requestsId) {
            return this.$api.baseApi().get('/realEstate/contract/feature/guest/cancel/' + requestsId)
                .then(response => {
                    return response.data?.objects.RequestedFeature;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        },
        generateOrderForContract(actions, contractId) {
            return this.$api.baseApi().get('/realEstate/contract/feature/guest/order/' + contractId)
                .then(response => {
                    return response.data?.objects.Order;
                }).catch(error => {
                    if (error?.response?.status === 404) {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s - failed to retrieve', arguments: ['feature']},
                                message: 'No features available for this contract'
                            },
                            {root: true});
                    } else {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s - failed to retrieve', arguments: ['feature']},
                                // message: (typeof error === "object" && error.response !== undefined ? error.response.data : error)
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                    }
                    throw error;
                });
        },
        payOrderForContract(actions, {orderId, request}) {
            return this.$api.baseApi().post('/realEstate/contract/feature/guest/order/pay/' + orderId, request)
                .then(response => {
                    return response.data?.objects.Order;
                }).catch(error => {
                    actions.dispatch('notifications/error',
                        {
                            title: {text: '%s - failed to retrieve', arguments: ['feature']},
                            message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                        },
                        {root: true});
                    return error;
                });
        }
    }
}
