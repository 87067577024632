import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from "vuex";

Vue.use(VueAxios, axios)

let config = window.config;

let interceptors = {
    response(response) {
        return response;
    },
    error(error) {
        if (error.response == null || error.response.data == null) {
            error.response = {data: {status: false, message: "connection failed"}}
        }
        return Promise.reject(error);
    }
}

let api = {
    internal() {
        return axios.create();
    },
    baseApi() {
        let headers = {
            'Content-Type': 'application/json',
            'Accept' : '*/*',
            "Authorization": `Bearer ${localStorage.Bearer || ''}`,
        };
        if (localStorage.base != null) {
            headers['org'] = localStorage.base
        }
        let instance = axios.create({
            baseURL: window.config.apiUrl,
            withCredentials: true,
            timeout: 100000,
            headers,
            crossDomain: true
        })
        instance.interceptors.response.use(interceptors.response, interceptors.error);
        return instance;
    },
    uploadApi(){
        let headers = {
            'Content-Type': 'multipart/form-data',
            // "Authorization": `Bearer ${localStorage.Bearer || ''}`,
        };
        if (localStorage.base != null) {
            headers['org'] = localStorage.base
        }
        let instance = axios.create({
            baseURL: window.config.apiUrl,
            timeout: 100000,
            headers,
            maxContentLength: 100,
            maxBodyLength: 100,
            withCredentials: true,
            crossDomain: true
        })
        instance.interceptors.response.use(interceptors.response, interceptors.error);
        return instance;
    },
    downloadApi() {
        let headers = {
            'Content-Type': 'application/json',
            "Content-Disposition": "attachment",
            'Accept' : '*/*',
            // "Authorization": `Bearer ${localStorage.Bearer || ''}`,
        };
        if (localStorage.base != null) {
            headers['org'] = localStorage.base
        }
        let instance = axios.create({
            baseURL: window.config.apiUrl,
            responseType:  'arraybuffer',
            timeout: 100000,
            withCredentials: true,
            headers
        })
        instance.interceptors.response.use(interceptors.response, interceptors.error);
        return instance;
    },
};

Vue.use({
    install(Vue) {
        Vue.prototype.$api = api;
    }
})

Vuex.Store.prototype.$api = api;



