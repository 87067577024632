import api from "../../services/api.service"


export const branchesModule = {
    namespaced: true,
    state: {
        branches: [],
        data: {},
        status: {
            loaded: false,
            error:''
        },
    },
    getters:{
        isLoaded(state){
            return state.status != null && state.status.loaded === true;
        },
        getError(state){
            return state.status != null && state.status.error
        },
        allBranches(state){
            if(state.branches == null)
                return [];

            return state.branches;
        },
        getOrganization(state){
            if(state.data == null || state.data.organization == null)
                return {}
            return state.data.organization;
        },
        getOrganizationById:(state)=>(orgId)=>{
            if(state.data.organization == null)
                return {};
            let org = state.data.organization[orgId];
            if(org == null)
                return {};

            return org
        },
        getMails(state){
            if(state.data == null || state.data.mail == null)
                return {};
            return state.data.mail;
        },

        getMailById:(state)=>(mailId)=>{
            if(state.data.mail == null)
                return {};
            let mail = state.data.mail[mailId];
            if(mail == null)
                return {};

            return mail
        },
        getKyc(state){
            if(state.data == null || state.data.kyc == null)
                return {};
            return state.data.kyc;
        },
        getKycById:(state)=>(kycId)=>{
            if(kycId <= 0 )
                return {};

            if(state.data.kyc == null)
                return {};
            let kyc = state.data.kyc[kycId];
            if(kyc == null)
                return {};

            return kyc
        },
        getGroups(state){
            if(state.data == null || state.data.groups == null)
                return {};
            return state.data.groups;
        }
    },
    mutations: {
        updateData(state, data) {
            console.log("updateData");
            let d = {};
            d.organization = data["Organization"];
            d.groups = data["BuGroups"];
            state.data = d;
        }
    },
    actions: {
        loadData({dispatch}) {
            return new Promise(function (resolve, reject) {
                dispatch('getData').then(() => {
                    dispatch('refresh', {page: 0, amountPerPage: 20}).then(r => resolve(r)).catch(e => reject(e))
                }).catch((e) => reject(e));
            });
        },
        getData({state, commit,dispatch}) {
            state.status.loaded = false;
            return new Promise(function (resolve, reject) {
                api.sendRequest('branches/data').then(
                    (ans) => {

                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        refresh({dispatch, state}, {filter}) {
            state.status.loaded = false;
            if (typeof filter !== "object")
                filter = {}
            return new Promise(function (resolve, reject) {
                api.sendRequest('branches/get',null,filter).then(
                    (ans) => {

                        if (ans["Branches"] == null)
                            reject('Branches - failed to refresh');

                        state.status.loaded = true;
                        state.branches = ans["Branches"];
                        resolve(state);
                    }, (error) => {
                        state.status.error = error.message

                        reject(error.message)
                    })
            })
        },
        //Get a branch
        get({dispatch}, {id}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('branches/id/'+id).then(
                    (ans) => {

                        resolve(ans.Branch)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        },
        update({dispatch, state}, {id, branch}) {
            return new Promise(function (resolve, reject) {
                //Check if branch is not undefined
                if (typeof branch !== "object")
                    reject("Invalid branch")
                //generate a new object in order to not update the branch object and add the field action
                let body = {...branch};
                //In order to prevent a bug or a mistake, the id must be send
                body['id'] = id;

                api.sendRequest('branches/update',null, body).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Update branch', message: 'branch updated successfully'},
                            {root: true});

                        //send error
                        resolve(ans)
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Update branch',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        register({dispatch, state}, {branch}) {
            return new Promise(function (resolve, reject) {
                //Check if branch is not undefined
                if (typeof branch !== "object")
                    reject("Invalid branch")
                api.sendRequest('branches/add',null, {...branch}).then(
                    (ans) => {
                        //In case of success send notification
                        dispatch('notifications/success',
                            {title: 'Register business unit', message: 'Business unit created successfully with id: ' + ans.BranchId},
                            {root: true});

                        //send error
                        resolve(ans["BranchId"])
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Register business unit',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        createRentalAccount(actions, {id}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('branches/createRentalAccount/'+id, null, null).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update Branch', message: 'Rental account created successfully'},
                            {root: true});

                        if (ans[""] == null)
                            return reject('Could not create rental account');

                        resolve(ans["BranchId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        reload() {
            return new Promise(function (resolve, reject) {
                api.sendRequest('branches/reload').then(
                    (ans) => {
                        resolve(ans)
                    }, (error) => {

                        reject(error.message)
                    })
            })
        }
    }
};
