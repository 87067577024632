import api from "../../../services/api.service"


export const depositModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },

        allDepositStatus() {
            return [
                { value: "NEW", text: ('NEW') },
                { value: "DEPOSITED", text: ('DEPOSITED') },
                { value: "REFUNDED", text: ('REFUNDED') },
                { value: "CANCELLED", text: ('CANCELLED') },
                { value: "PAUSED", text: ('PAUSED') },
                { value: "FAILED", text: ('FAILED') }
            ];
        },



    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions,id){
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Deposit  ID '+id+' copied'},
                {root: true});

        },


        getAllDeposits(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {

                    api.sendRequest('deposit/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    },filter).then(
                        (ans) => {
                            if (ans["Deposits"] == null)
                                return reject('Could not get Deposits ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },

        getDepositById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('deposit/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Deposit"] == null)
                            return reject('Could not get deposit ');

                        resolve(ans["Deposit"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateDeposit(actions,{deposit}){
            return new Promise((resolve, reject) => {

                api.sendRequest('deposit/update',null, deposit).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update deposit', message: 'Deposit updated successfully'},
                            {root: true});

                        if (ans["RowsUpdated"] == null)
                            return reject('Could not update deposit ');

                        resolve(ans["RowsUpdated"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        handleDepositEvent(actions,{event,id,amount}){
            return new Promise((resolve, reject) => {

                api.sendRequest('deposit/handleEvent/'+id+'/'+event,{amount:amount},null).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Handle deposit', message: 'Event handled successfully'},
                            {root: true});

                        if (ans["Deposit"] == null)
                            return reject('Could not handle event ');

                        resolve(ans["Deposit"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sendEmail(actions,deposit){
            return new Promise((resolve, reject) => {

                api.sendRequest('deposit/sendEmail',null, deposit).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Email Support', message: 'Email was sent successfully'},
                            {root: true});

                        if (ans["DepositId"] == null)
                            return reject('Could not sent email');

                        resolve(ans["DepositId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addDeposit(actions,{deposit}){
            return new Promise((resolve, reject) => {
                api.sendRequest('deposit/create', null,deposit).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add deposit', message: 'Deposit created successfully'},
                            {root: true});

                        if (ans["Deposit"] == null)
                            return reject('Could not create deposit ');

                        console.log("deposit answer created: ",ans)
                        resolve(ans["Deposit"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
