import api from "../../../services/api.service"


export const routerModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },

        allRouterStatus() {
            return [
                { value: "online", text: ('ONLINE') },
                { value: "offline", text: ('OFFLINE') },
                { value: "inProgress", text: ('IN PROGRESS') },
                { value: "error", text: ('ERROR') }
            ];
        },



    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions,id){
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Router  ID '+id+' copied'},
                {root: true});

        },


        getAllRouters(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {

                    api.sendRequest('router/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    },filter).then(
                        (ans) => {
                            if (ans["Routers"] == null)
                                return reject('Could not get Routers ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },

        getRouterById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('router/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Router"] == null)
                            return reject('Could not get router ');

                        resolve(ans["Router"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('router/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateRouter(actions,{router}){
            return new Promise((resolve, reject) => {

                api.sendRequest('router/update',null, router).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update router', message: 'Router updated successfully'},
                            {root: true});

                        if (ans["RowsUpdated"] == null)
                            return reject('Could not update router ');

                        resolve(ans["RowsUpdated"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sendEmail(actions,router){
            return new Promise((resolve, reject) => {

                api.sendRequest('router/sendEmail',null, router).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Email Support', message: 'Email was sent successfully'},
                            {root: true});

                        if (ans["RouterId"] == null)
                            return reject('Could not sent email');

                        resolve(ans["RouterId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addRouter(actions,{router}){
            return new Promise((resolve, reject) => {
                api.sendRequest('router/create', null,router).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add router', message: 'Router created successfully'},
                            {root: true});

                        if (ans["Router"] == null)
                            return reject('Could not create router ');

                        console.log("router answer created: ",ans)
                        resolve(ans["Router"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
