import api from "../../../services/api.service"


export const electricityModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },

        allElectricityStatus() {
            return [
                { value: "active", text: ('Active') },
                { value: "inactive", text: ('Inactive') },
                { value: "disturbance", text: ('Disturbance') },
                { value: "in progress", text: ('In progress') }
            ];
        },



    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions,id){
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Electricity  ID '+id+' copied'},
                {root: true});

        },


        getAllElectricitys(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {

                    api.sendRequest('electricity/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    },filter).then(
                        (ans) => {
                            if (ans["Electricitys"] == null)
                                return reject('Could not get Electricitys ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },

        getElectricityById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('electricity/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Electricity"] == null)
                            return reject('Could not get electricity ');

                        resolve(ans["Electricity"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateElectricity(actions,{electricity}){
            return new Promise((resolve, reject) => {

                api.sendRequest('electricity/update',null, electricity).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update electricity', message: 'Electricity updated successfully'},
                            {root: true});

                        if (ans["RowsUpdated"] == null)
                            return reject('Could not update electricity ');

                        resolve(ans["RowsUpdated"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sendEmail(actions,electricity){
            return new Promise((resolve, reject) => {

                api.sendRequest('electricity/sendEmail',null, electricity).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Email Support', message: 'Email was sent successfully'},
                            {root: true});

                        if (ans["ElectricityId"] == null)
                            return reject('Could not sent email');

                        resolve(ans["ElectricityId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('electricity/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        addElectricity(actions,{electricity}){
            return new Promise((resolve, reject) => {
                api.sendRequest('electricity/create', null,electricity).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add electricity', message: 'Electricity created successfully'},
                            {root: true});

                        if (ans["Electricity"] == null)
                            return reject('Could not create electricity ');

                        console.log("electricity answer created: ",ans)
                        resolve(ans["Electricity"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
